import React from "react"
import styled from "styled-components"
import { lighten } from "polished"

import { ButtonPrimary } from "../../components/Button"

const ContactInput = styled.input`
  height: ${props => props.theme.spaces.xl};
  background: ${props => props.theme.color.white};
  margin: ${props => props.theme.spaces.stack.xs};
  border: 1px solid ${props => props.theme.color.border.light};
  border-radius: 6px;
  font-size: 14px;
  font-family: "Inter UI", sans-serif;
  padding: ${props => props.theme.spaces.insetSquish.xs};
  color: ${props => props.theme.color.font.regular};

  :focus {
    border: 1px solid ${props => props.theme.color.border.regular};
    outline-width: 0;
  }
`
const TextAreaInput = styled.textarea`
  background: ${props => props.theme.color.white};
  margin: ${props => props.theme.spaces.stack.m};
  border: 1px solid ${props => props.theme.color.border.light};
  border-radius: 6px;
  font-size: 14px;
  font-family: "Inter UI", sans-serif;
  padding: ${props => props.theme.spaces.insetSquish.xs};
  color: ${props => props.theme.color.font.regular};
  line-height: 20px;

  :focus {
    border: 1px solid ${props => props.theme.color.border.regular};
    outline-width: 0;
  }
`

const Label = styled.label`
  color: ${props => props.theme.color.font.light};
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-left: ${props => props.theme.spaces.xxs};
`

const FormArea = styled.div`
  border: 0;
  max-width: 100%;
  min-width: 50%;
  border-radius: 6px;
  padding: ${props => props.theme.spaces.inset.xl};
  background: ${props => lighten(0.025, props.theme.color.palette.greyBlue0)};
  box-shadow: ${props => props.theme.shadow.glowShadow};
  margin: ${props => props.theme.spaces.stack.xxl};
`

function ContactForm() {
  return (
    <>
      <FormArea>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Label>Name</Label>
          <ContactInput name="name" type="text" id="name" />
          <Label>E-Mail</Label>
          <ContactInput name="email" type="email" id="email" />
          <Label>Telefon</Label>
          <ContactInput name="phone" type="tel" id="phone" />
          <Label>Nachricht</Label>
          <TextAreaInput name="message" cols="30" rows="15" id="message" />

          <ButtonPrimary type="submit" style={{ width: "150px" }}>
            Senden
          </ButtonPrimary>
        </form>
      </FormArea>
    </>
  )
}
export default ContactForm
