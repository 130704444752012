import React from "react"
import styled from "styled-components"

import SEO from "../components/SEO"

import Layout from "../layout"
import ContentRow from "../components/ContentRow"
import ContactForm from "../containers/ContactForm"
import ContactText from "../containers/ContactText"

const ContactContentRow = styled(ContentRow)`
  display: flex;
  align-content: stretch;

  @media (max-width: ${props => props.theme.media.tablet}) {
    flex-direction: column;
  }
`

function Contact() {
  return (
    <>
      <Layout>
        <SEO
          title="Kontakt"
          keywords={[
            `Into Numbers`,
            `Data Science`,
            `Web Development`,
            `Digitalisierung`,
            `Trainings`,
            `Business Intelligence`,
            `Kontakt`,
          ]}
        />
        <ContactContentRow>
          <ContactText />
          <ContactForm />
        </ContactContentRow>
      </Layout>
    </>
  )
}
export default Contact
