import { lighten } from "polished"
import styled from "styled-components"

const BaseButton = styled.button`
  cursor: pointer;
  appearance: none;
  &:focus {
    outline: none;
  }

  padding: ${props => props.theme.spaces.insetSquish.l};

  border-radius: 6px;
  border: 0;

  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter UI", sans-serif;

  transition: background 0.4s;

  box-shadow: ${props => props.theme.shadow.mainShadow};

  > * :not(:last-child) {
    margin: ${props => props.theme.spaces.inline.s};
  }
`

const ButtonPrimary = styled(BaseButton)`
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.intoNumbers.darkBlue};
  :hover {
    background: ${props =>
      lighten(0.05, props.theme.color.intoNumbers.darkBlue)};
  }
`

export { ButtonPrimary }
