import React from "react"
import styled from "styled-components"
// import Image from "../components/image"
// import SEO from "../components/seo"

import useTheme from "../../layout/theme/useTheme"

import LetterIcon from "../../svgs/envelope-solid.svg"
import PhoneIcon from "../../svgs/phone-solid.svg"
import LocationIcon from "../../svgs/map-marker-solid.svg"

const TextArea = styled.div`
  margin: ${props => props.theme.spaces.inline.xxxl};
  margin-top: ${props => props.theme.spaces.xxxl};
  max-width: 400px;
  min-width: 200px;
  align-content: center;

  @media (max-width: ${props => props.theme.media.tablet}) {
    margin-top: 0;
    min-width: 100%;
  }
`

const ContactCatchPhrase = styled.div`
  margin: ${props => props.theme.spaces.stack.xxl};

  @media (max-width: ${props => props.theme.media.tablet}) {
    text-align: center;
  }
`

const ContactIntoNumbers = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin: ${props => props.theme.spaces.stack.l};

  @media (max-width: ${props => props.theme.media.tablet}) {
    text-align: center;
  }
`

const TextRow = styled.div`
  display: flex;
  margin: 0 auto;
  margin-bottom: ${props => props.theme.spaces.l};

  @media (max-width: ${props => props.theme.media.tablet}) {
    width: 240px;
  }
`

const StyledLetterIcon = styled(LetterIcon)`
  height: 25px;
  width: 25px;
  color: ${props => props.theme.color.intoNumbers.darkBlue};
  padding: ${props => props.theme.spaces.inline.m};
`

const StyledPhoneIcon = styled(PhoneIcon)`
  height: 22px;
  width: 25px;
  color: ${props => props.theme.color.intoNumbers.darkBlue};
  padding: ${props => props.theme.spaces.inline.m};
`

const StyledLocationIcon = styled(LocationIcon)`
  height: 25px;
  width: 25px;
  color: ${props => props.theme.color.intoNumbers.darkBlue};
  padding: ${props => props.theme.spaces.inline.m};
`

const ConactWrapper = styled.div``

function ContactText() {
  const { spaces } = useTheme()
  return (
    <>
      <TextArea>
        <ContactCatchPhrase>
          Schicken Sie uns Informationen zu Ihrem Projekt, wir freuen uns darauf
          Sie beraten zu d&uuml;rfen!
        </ContactCatchPhrase>
        <ContactIntoNumbers>INTO NUMBERS Data Science GmbH</ContactIntoNumbers>
        <ConactWrapper>
          <TextRow>
            <StyledLocationIcon />
            <div>
              <p style={{ margin: spaces.stack.xxs }}>Erdbergstrasse 10/67</p>
              <p style={{ margin: spaces.stack.xxs }}>1030 Wien</p>
              <p style={{ margin: spaces.stack.xxs }}>&Ouml;sterreich</p>
            </div>
          </TextRow>

          <TextRow>
            <StyledLetterIcon />
            office@intonumbers.com
          </TextRow>
          <TextRow style={{ marginBottom: spaces.xxl }}>
            <StyledPhoneIcon />
            +43 (0)720 701010
          </TextRow>
        </ConactWrapper>
      </TextArea>
    </>
  )
}
export default ContactText
